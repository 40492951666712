<template>
  <div class="bg">
    <div class="main-box">
      <breadcurmb :menuList="menuList"></breadcurmb>
      <div class="file-box">
        <div class="file-title">
          {{ standardsInfo.title }}
        </div>
        <div class="bottom-line"></div>
        <div class="file-msg">
          <div class="img-text">
            <img src="@assets/caseCenter/p-time.png" alt="">
            发布时间：{{ standardsInfo.publishTime || '-' }}
          </div>
          <div class="img-text">
            <img src="@assets/caseCenter/views.png" alt="">
            浏览次数：{{ standardsInfo.viewCount || 0 }}
          </div>
        </div>
        <!--        pdf预览-->
        <div class="pdf-box" v-loading="loading">
          <div class="empty" v-if="numPages.length === 0 && !loading">
            <el-empty description="暂无内容"></el-empty>
          </div>
          <template v-else>
            <pdf
              v-for="item in numPages"
              :key="item"
              :page="item"
              :src="standardsInfo.pdfUrl"
              ref="pdf"></pdf>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pdf from 'vue-pdf'
  import Breadcurmb from '@/components/breadcrumb/Breadcurmb'

  import {
    noticeData
  } from '@/api/mock/homeIndex'

  export default {
    name: 'NoticeDetail',
    components: { Breadcurmb, pdf },
    data() {
      return {
        loading: false,
        standardsInfo: {},
        noticeData: noticeData,
        numPages: [],
        menuList: [
          {
            name: '通知公告详情',
            path: ''
          }
        ]
      }
    },
    created() {
      this.getTableData()
    },
    methods: {
      getTableData() {
        this.loading = true
        this.standardsInfo = this.noticeData.find(item => item.id === this.$route.query.id)
        this.getNumPages()
      },
      getNumPages() {
        let loadingTask = pdf.createLoadingTask(this.standardsInfo.pdfUrl)
        loadingTask.promise.then(pdf => {
          this.numPages = pdf.numPages > 5 ? 4 : pdf.numPages
          this.loading = false
        }).catch(err => {
          this.loading = false
          console.error('pdf 加载失败', err)
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
  margin-top: 85px;
}

.main-box {
  width: 1000px;
  margin: 0 auto 60px;
}

.title {
  padding: 33px 0;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .bottom-line {
    width: 30px;
    margin-top: 8px;
    border-bottom: 3px solid @primary-color;
  }
}

.file-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .file-title {
    font-size: 24px;
    color: @primary-color;
    font-weight: bold;
    text-align: center;
    margin: 50px 0 20px;
  }

  .bottom-line {
    width: 100%;
    border-bottom: 1px solid rgba(187, 187, 187, 1);
  }

  .file-msg {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    color: #A19F9F;

    .img-text {
      display: flex;
      align-items: center;

      img {
        margin-right: 3px;
      }
    }
  }

  .pdf-box {
    width: 100%;
    height: 600px;
    overflow-y: scroll;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
