<template>
  <div class="bg">
    <div class="main-box" v-loading="loading">
      <breadcurmb :menuList="menuList"></breadcurmb>
      <div class="title">
        <div>产品展示</div>
        <div class="bottom-line"></div>
      </div>
      <div class="product-box">
        <div class="base-box">
          <el-row class="slideShow">
            <el-carousel class="photo" :span="24" ref="cardShow" height="100%">
              <el-carousel-item
                v-if="index<5"
                v-for="(item,index) in productInfo.productImage"
                :key="index"
              >
                <img class="product-img" :src="item" alt="">
              </el-carousel-item>
              <!--            <img class="product-img" :src="productInfo.productImage" alt=""/>-->
            </el-carousel>
          </el-row>
        </div>
        <div class="base-box">
          <div class="product-title" :title="productInfo.productName">产品名称：{{ productInfo.productName }}</div>
          <div class="bottom-line"></div>
          <div class="product-msg">
            <div class="icon-text">
              <img src="@/assets/index/query/home.png" alt="">
              <span>企业名称</span>
            </div>
            <div class="text">
              {{ productInfo.companyName }}
            </div>
            <div class="icon-text">
              <img src="@/assets/index/query/base.png" alt="">
              <span>基地</span>
            </div>
            <div class="text">
              {{ productInfo.productionBaseName }}
            </div>
            <div class="icon-text">
              <img src="@/assets/index/query/certificate.png" alt="">
              <span>证书编号</span>
            </div>
            <div class="text">{{ productInfo.certificateNumber }}</div>
          </div>
        </div>
      </div>
      <div class="title">
        <div>认证证书</div>
        <div class="bottom-line"></div>
      </div>
      <div v-if="productInfo.certificateImagePath" class="certificate-img">
        <img :src="productInfo.certificateImagePath" alt="" width="100%" height="100%">
      </div>
      <el-empty v-else description="暂无认证证书信息"></el-empty>
      <div v-if="productInfo.certificationCategory!=='D'">
        <div class="title">
          <div>供应平台</div>
          <div class="bottom-line"></div>
        </div>
        <div class="apply-box" v-if="productInfo.certificationCategory!=='D' && platformData.length >0">
          <a :href="item.url" target="_blank" class="btn-box" v-for="(item,index) in platformData" :key="index">
            <img :src="item.img" alt="" width="100%" height="100%"/>
            <span>{{ item.name }}</span>
          </a>
        </div>
        <div v-else-if="productInfo.certificateNumber ==='A8644202408000029'">
          <a
            href=""
            class="title-box">
            <span><i class="el-icon-shopping-bag-2 m-r-1"></i>线下实体专柜购买</span>
          </a>
        </div>
        <div v-else-if="productInfo.certificateNumber ==='A8644202407000025'">
          <a
            href=""
            class="title-box">
            <span><i class="el-icon-shopping-bag-2 m-r-1"></i>主要线下销售：橱柜商博洛尼（全屋高端定制）</span>
          </a>
        </div>
        <div v-else-if="productInfo.certificateNumber ==='B8644202410000050'">
          <a
            href=""
            class="title-box">
            <span><i
              class="el-icon-shopping-bag-2 m-r-1"></i>线下平台、商超等：<br>1.中山、珠海、佛山、江门、广州的钱大妈门店<br>2.中山的壹加壹、喜客多商超</span>
          </a>
        </div>
        <el-empty v-else description="暂无供应平台信息"></el-empty>
      </div>
      <div v-if="productInfo.certificateNumber ==='D8644202412000008'">
        <div class="title">
          <div>企业网站</div>
          <div class="bottom-line"></div>
        </div>
        <div class="apply-box-2">
          <a
            href="https://www.lsjccx.org.cn/database/manufacturer/details?id=713209552193518592"
            target="_blank"
            class="btn-box">
            <img src="@/assets/index/query/langdao.png" alt="" width="100%" height="100%"/>
            <span>广东朗道建材科技有限公司</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Breadcurmb from '@/components/breadcrumb/Breadcurmb'
  import {
    productList
  } from '@/api/mock/homeIndex'
  import { getCertificateById } from './api/api'

  export default {
    name: 'QueryDetail',
    components: { Breadcurmb },
    data() {
      return {
        productList: productList,
        productInfo: {},
        platformData: [],
        loading: false,
        menuList: [
          {
            name: '认证查询',
            path: '/bayareacertification/query'
          },
          {
            name: '认证详情',
            path: ''
          }
        ]
      }
    },
    created() {
      this.getNewsDetail()
    },
    methods: {
      getNewsDetail() {
        this.loading = true
        getCertificateById({ id: this.$route.query.id }).then(res => {
          this.productInfo = Object.assign(res.data, {
            productImage: res.data.productImage ? res.data.productImage.split(',') : ''
          })
          let data = res.data.productSupplyPlatformDetailDataList || []
          this.platformData = data.map(item => {
            return {
              name: item.supplyPlatformName,
              img: item.supplyPlatformLogo,
              url: item.platformUrl
            }
          })
          this.addWarker(this.productInfo.certificateImagePath, '仅供本网站展示，不得转载\n      https://gbacpc.org.cn')
          this.loading = false
        }).finally(() => {
          this.loading = false
          this.$nextTick(() => {
            this.$zh_tran(localStorage.getItem('lang'))
          })
        })
      },
      // 添加水印
      addWarker(url, warkerName) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const image = new Image()
        image.src = url
        image.setAttribute('crossOrigin', 'Anonymous')
        image.onload = () => {
          canvas.width = image.width
          canvas.height = image.height
          ctx.drawImage(image, 0, 0)

          // 绘制水印 旋转45度
          ctx.font = '40px Arial'
          ctx.rotate(270)
          ctx.fillStyle = 'rgba(181,177,177,1)' // 白色半透明
          // 水印文字实现换行效果
          warkerName = warkerName.split('\n')
          for (let i = 0; i < 4; i++) {
            for (let j = 0; j < 2; j++) {
              warkerName.forEach((item, index) => {
                ctx.fillText(item, ((canvas.width / 2) + 30) * j, (canvas.height / 4) * i + 50 * index)
              })
            }
          }

          // 将canvas转换为图片源
          this.productInfo.certificateImagePath = canvas.toDataURL('image/png')
        }
      }
    }
  }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .bg {
    width: 100%;
    background: url("~@/assets/index/query/query-bg.png") no-repeat;
  }

  .main-box {
    width: 1000px;
    margin: 85px auto 60px;

    .title {
      margin: 33px 0 20px 0;
      font-size: 24px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bottom-line {
        width: 30px;
        margin-top: 8px;
        border-bottom: 3px solid @primary-color;
      }
    }

    .product-box {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      height: 400px;
    }

    .product-title {
      width: 100%;
      font-size: 24px;
      color: @primary-color;
      font-weight: bold;
      margin: 20px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; /* 设置为WebKit内核的弹性盒子模型 */
      -webkit-box-orient: vertical; /* 垂直排列 */
      -webkit-line-clamp: 2; /* 限制显示两行 */
    }

    .product-msg {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
      margin-top: 10px;

      .icon-text {
        display: flex;
        align-items: center;

        img {
          width: 35px;
          height: 35px;
          margin-right: 8px;
        }

        span {
          font-size: 18px;
          font-weight: bold;
        }
      }

      .text {
        margin: 0 0 23px 43px;
      }
    }

    .base-box {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .bottom-line {
        width: 100%;
        border-bottom: 1px solid #cccccc;
      }

      .slideShow {
        position: relative;
        width: calc(100% - 50px);
        height: 100%;
        text-align: center;
        //margin-bottom: 13px;

        .photo {
          height: 100%;

          img {
            //width: 100%;
            height: calc(100% - 50px);
          }
        }

        .photo:hover {
          cursor: pointer;
        }
      }
    }

    .certificate-img {
      width: 632px;
      margin: 0 auto;
    }

    .product-img {
      max-width: 420px;
      height: 100%;
      border-radius: 20px;
    }

    .apply-box {
      width: 1000px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 80px;
        font-size: 20px;
        font-weight: bold;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(187, 187, 187, 0.44);

        img {
          width: 48px;
          height: 48px;
        }

        span {
          margin-left: 10px;
        }
      }
    }

    .title-box {
      font-size: 20px;

      .m-r-1 {
        margin-right: 10px;
      }
    }

    .apply-box-2 {
      width: 1000px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        font-size: 20px;
        font-weight: bold;
        border-radius: 10px;
        padding: 0 20px;
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(187, 187, 187, 0.44);

        img {
          width: 100px;
          height: 100%;
        }

        span {
          margin-left: 20px;
        }
      }
    }
  }
}

/deep/ .el-carousel__button {
  height: 6px;
  background-color: @primary-color;
}

/deep/ .el-carousel__indicators--horizontal {
  bottom: 45px;
}

@media (max-width: 768px) {
  .bg {
    width: 100%;
    background: url("~@/assets/index/query/query-bg.png") no-repeat;
  }

  .main-box {
    width: 100%;
    margin: 85px auto 60px;
    padding: 0 10px;

    .title {
      margin: 15px 0 20px 0;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bottom-line {
        width: 30px;
        margin-top: 8px;
        border-bottom: 3px solid @primary-color;
      }
    }

    .slideShow {
      position: relative;
      width: 90%;
      height: 340px;
      padding: 0 10px;
      text-align: center;
      //margin-bottom: 13px;

      .photo {
        height: 100%;

        img {
          //width: 100%;
          height: calc(100% - 10px);
        }
      }

      .photo:hover {
        cursor: pointer;
      }
    }

    @media (max-width: 400px) {
      .product-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .slideShow {
        position: relative;
        width: 100%;
        height: 300px;
        padding: 0 10px;
        //margin-bottom: 13px;

        .photo {
          height: 100%;

          img {
            //width: 100%;
            height: calc(100% - 10px);
          }
        }

        .photo:hover {
          cursor: pointer;
        }
      }
    }
    //  400px-500px 之间
    @media (min-width: 400px) and (max-width: 499px) {
      .product-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .slideShow {
        position: relative;
        width: 100%;
        height: 300px;
        padding: 0 10px;
      }
    }
    @media (min-width: 500px) and (max-width: 550px) {
      .product-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .slideShow {
        position: relative;
        width: 100%;
        height: 340px;
        padding: 0 10px;
      }
    }
    @media (min-width: 551px) and (max-width: 650px) {
      .product-box {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .slideShow {
        position: relative;
        width: 100%;
        height: 350px;
        padding: 0 10px;
      }
    }
    @media (min-width: 651px) and (max-width: 768px) {
      .product-box {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .slideShow {
        position: relative;
        width: 100%;
        height: 350px;
        padding: 0 10px;
      }
    }

    .product-title {
      width: 100%;
      font-size: 20px;
      color: @primary-color;
      font-weight: bold;
      margin: 20px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box; /* 设置为WebKit内核的弹性盒子模型 */
      -webkit-box-orient: vertical; /* 垂直排列 */
      -webkit-line-clamp: 2; /* 限制显示两行 */
    }

    .product-msg {
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      margin-top: 10px;

      .icon-text {
        display: flex;
        align-items: center;

        img {
          width: 35px;
          height: 35px;
          margin-right: 8px;
        }

        span {
          font-size: 16px;
          font-weight: bold;
        }
      }

      .text {
        margin: 0 0 23px 43px;
      }
    }

    .base-box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 10px;

      .bottom-line {
        width: 100%;
        border-bottom: 1px solid #cccccc;
      }
    }

    .certificate-img {
      width: 60%;
      margin: 0 auto;
    }

    .product-img {
      max-width: 420px;
      height: 100%;
      border-radius: 20px;
    }

    .apply-box {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 65px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(187, 187, 187, 0.44);

        img {
          width: 25px;
          height: 25px;
        }

        span {
          margin-left: 5px;
        }
      }
    }

    .title-box {
      font-size: 16px;

      .m-r-1 {
        margin-right: 10px;
      }
    }

    .apply-box-2 {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        font-size: 20px;
        font-weight: bold;
        border-radius: 10px;
        padding: 0 20px;
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(187, 187, 187, 0.44);

        img {
          width: 100px;
          height: 100%;
        }

        span {
          margin-left: 20px;
        }
      }
    }
  }

  /deep/ .el-carousel__indicators--horizontal {
    bottom: 25px;
  }
}
</style>
