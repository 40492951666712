<template>
  <div style="background: none">
    <div class="side-box">
      <div class="side-title">
        <div>{{ title }}</div>
        <div class="more" @click="viewMore">返回</div>
      </div>
      <div style="flex: 1">
        <ul>
          <li
            class="ellipsis"
            :class="{ 'active': currentNewsId === item.id }"
            v-for="item in caseCenterList"
            :key="item.id"
            :title="item.title"
            @click="changeNews(item)">
            <i class="el-icon-tickets"></i> {{ item.title }}
          </li>
        </ul>
      </div>
      <div class="side-btn">
        <span @click="pagePre">上一页</span>
        {{ currentPage }}/{{ totalPage }}
        <span @click="pageNext">下一页</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { getAction } from '@api/manage'
  import { message } from 'ant-design-vue'

  export default {
    name: 'LeftNewsDataList',
    components: {},
    props: {
      title: {
        type: String,
        default: ''
      },
      caseCenterList: {
        type: Array,
        default: () => []
      },
      currentNewsId: {
        type: String,
        default: ''
      },
      currentPage: {
        type: Number,
        default: 1
      },
      totalPage: {
        type: Number,
        default: 1
      }
    },
    data() {
      return {}
    },
    created() {
    },
    methods: {
      viewMore() {
        this.$emit('viewMore')
      },
      changeNews(record) {
        this.$emit('changeNews', record)
      },
      pageNext() {
        this.$emit('pageNext')
      },
      pagePre() {
        this.$emit('pagePre')
      }
    }
  }
</script>

<style lang="less" scoped>

.side-box {
  position: sticky;
  left: 3%;
  top: 85px;
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 550px;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
  //box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);

  .side-title {
    padding: 0 0 10px;
    color: #228EAF;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .more {
      cursor: pointer;
      font-size: 14px;
    }
  }

  ul {
    list-style: none;

    li {
      font-size: 16px;
      line-height: 26px;
      margin: 20px 0;
      color: #999;
      border-bottom: none;
      height: 26px;

      &:hover {
        cursor: pointer;
        color: @primary-color;
      }
    }

    .active {
      color: @primary-color;
    }

    .ellipsis {
      white-space: nowrap; /* 禁止文本换行 */
      overflow: hidden; /* 隐藏溢出的文本 */
      text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
      width: 250px; /* 设置文本容器的宽度 */
    }
  }

  .side-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      cursor: pointer;

      &:hover {
        opacity: 0.85;
        color: @primary-color;
      }
    }
  }
}

</style>
