/*
* @Description:分页组件
* @Version: 1.0
* @Author: Wangfan
*/
<template>
  <div class="page">
    <!--    分页-->
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :hide-on-single-page="value"
      :current-page="currentPage"
      :pager-count="5"
      :page-size="pageSize"
      layout="prev, pager, next"
      :total="total">
    </el-pagination>
    <!--    go按钮-->
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="slot"
      :total="total"
      :hide-on-single-page="value"
    >
      <!--      <el-button class="ensure-btn">GO</el-button>-->
    </el-pagination>
  </div>
</template>

<script>
  export default {
    name: 'PPaginationBase',
    props: {
      // 初始页
      currentPage: {
        type: Number,
        default: 1
      },
      // 每页的数据量
      pageSize: {
        type: Number,
        default: 8
      },
      // 总共的数据量
      total: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        value: false
      }
    },
    watch: {},
    methods: {
      // 向父组件提交当前page数据
      handleSizeChange(page) {
        // this.size = page
        this.$emit('update:pageSize', page)
      },
      // 向父组件提交当前current数据
      handleCurrentChange(current) {
        console.log('current', current)
        // this.page = current
        this.$emit('currentPage', current)
        // 表示将子组件改变的值传递给父组件,用于事件处理
        this.$emit('currentPageChange', current)
      }
    }
  }
</script>

<style scoped lang="less">

.page {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .ensure-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3px;
    width: 31px;
    height: 30px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(11, 12, 12, 90);
    border: 1px solid #DCDFE6;
  }

  .ensure-btn:hover {
    color: @primary-color;
    background-color: #fff;
  }

}

/deep/ .el-pagination {
  display: flex;
}

/deep/ .el-pagination span:not([class*=suffix]) {
  font-size: 16px;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: @primary-color;
  //border-radius: 30px;
  /*margin-right: -2px;*/
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #FFFFFF;
  opacity: 0.8;
  cursor: pointer;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: @primary-color;
}

@media (min-width: 768px) {
  /deep/ .el-pagination.is-background .btn-next {
    min-height: 32px;
    min-width: 50px;
    line-height: 32px;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid rgba(187, 187, 187, 100);
    margin-left: 10px;
  }

  /deep/ .el-pagination.is-background .btn-next:hover {
    color: @primary-color;
  }

  /deep/ .el-pagination.is-background .btn-prev {
    min-height: 32px;
    min-width: 50px;
    line-height: 32px;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid rgba(187, 187, 187, 100);
    margin-right: 10px;
  }

  /deep/ .el-pagination.is-background .btn-prev:hover {
    color: @primary-color;
  }

  /deep/ .el-pagination.is-background .el-pager li {
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 14px;
    background-color: #fff;
    /*border: 1px solid rgba(187, 187, 187, 100);*/
    /*margin-right: -2px;*/
    border-radius: 4px;
    margin: 0 8px;
  }

  /deep/ .el-pagination.is-background .el-pager li:hover {
    background-color: rgb(217, 221, 225);
    //border-radius: 30px;
  }

  /deep/ .el-icon-arrow-left:before {
    content: "上一页";
  }

  /deep/ .el-icon-arrow-right:before {
    content: "下一页";
  }

  /deep/ .el-pagination__jump {
    min-height: 32px !important;
    display: flex !important;
    font-size: 14px !important;
  }

  /deep/ .el-pagination__editor {
    min-height: 38px;
    min-width: 38px;
    margin: 0 5px;
  }

  /deep/ .el-pagination__editor.el-input {
    width: 50px;

  }

  /deep/ .el-pagination__editor.el-input .el-input__inner {
    min-height: 32px;
    font-size: 14px;
  }

  /deep/ .el-input__inner:focus {
    border-color: @primary-color !important;
  }

  /deep/ .el-pagination button, .el-pagination span:not([class*=suffix]) {
    min-width: 43.5px;
  }
}

@media (max-width: 768px) {
  /deep/ .el-pagination.is-background .btn-next {
    min-height: 25px;
    min-width: 30px;
    line-height: 25px;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid rgba(187, 187, 187, 100);
    margin-left: 10px;
  }

  /deep/ .el-pagination.is-background .btn-next:hover {
    color: @primary-color;
  }

  /deep/ .el-pagination.is-background .btn-prev {
    min-height: 25px;
    min-width: 30px;
    line-height: 25px;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid rgba(187, 187, 187, 100);
    margin-right: 10px;
  }

  /deep/ .el-pagination.is-background .btn-prev:hover {
    color: @primary-color;
  }

  /deep/ .el-pagination.is-background .el-pager li {
    height: 25px;
    width: 25px;
    line-height: 25px;
    font-size: 14px;
    background-color: #fff;
    /*border: 1px solid rgba(187, 187, 187, 100);*/
    /*margin-right: -2px;*/
    border-radius: 4px;
    margin: 0 8px;
  }

  /deep/ .el-pagination.is-background .el-pager li:hover {
    background-color: rgb(217, 221, 225);
    //border-radius: 30px;
  }



  /deep/ .el-pagination__jump {
    min-height: 32px !important;
    display: flex !important;
    font-size: 14px !important;
  }

  /deep/ .el-pagination__editor {
    min-height: 38px;
    min-width: 38px;
    margin: 0 5px;
  }

  /deep/ .el-pagination__editor.el-input {
    width: 50px;

  }

  /deep/ .el-pagination__editor.el-input .el-input__inner {
    min-height: 32px;
    font-size: 14px;
  }

  /deep/ .el-input__inner:focus {
    border-color: @primary-color !important;
  }

  /deep/ .el-pagination button, .el-pagination span:not([class*=suffix]) {
    min-width: 43.5px;
  }
}
</style>
