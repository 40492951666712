<template>
  <div class="main-box">
    <breadcurmb :menuList="menuList"></breadcurmb>
    <!--    <div class="title">-->
    <!--      <div>动态详情</div>-->
    <!--      <div class="bottom-line"></div>-->
    <!--    </div>-->
    <div v-loading="loading" class="height-box">
      <el-empty description="暂无数据" v-if="!newsInfo.title && !loading"></el-empty>
      <div class="news-box" v-else>
        <div class="news-title">{{ newsInfo.title }}</div>
        <div class="news-msg">
          <span> <img src="@assets/caseCenter/p-time.png" alt="">
         发布时间：{{ newsInfo.releaseTime }}
        </span>
          <span>
           来源：{{ newsInfo.source }}
        </span>
          <span><img src="@assets/caseCenter/views.png" alt="">
          浏览次数：{{ newsInfo.viewCount }}
        </span>
        </div>
        <div class="news-content">
          <video-player
            style="width: 99%;height: 100%;margin:0 auto;box-shadow:5px 5px 8px #888888"
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
          ></video-player>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <span class="other-title" @click="handlePrevious(newsInfo.previousId)">上一篇：{{
          newsInfo.previousTitle || '暂无更多'
        }}</span>
      <span class="other-title" @click="handleNext(newsInfo.nextId)">下一篇：{{
          newsInfo.nextTitle || '暂无更多'
        }}</span>
    </div>
  </div>
</template>

<script>
  import Breadcurmb from '@/components/breadcrumb/Breadcurmb'
  import { getAction } from '@api/manage'
  import { message } from 'ant-design-vue'

  export default {
    name: 'AuditStoryDetail',
    components: { Breadcurmb },
    data() {
      return {
        loading: false,
        caseCenterList: [],
        newsInfo: {},
        pageId: '',
        type: '',
        menuList: [
          {
            name: '典型案例',
            path: '/bayareacertification/classicCase'
          },
          {
            name: '典型案例详情',
            path: ''
          }
        ]
      }
    },
    computed: {
      index: function () {
        return this.caseCenterList.findIndex(item => item.id === this.pageId)
      },
      playerOptions: function () {
        return {
          playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
          autoplay: false, // 如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: 'video/mp4',
            src: this.newsInfo.uploadVideoUrl // url地址
          }],
          poster: '', // 你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true // 全屏按钮
          }
        }
      }
    },
    created() {
      this.pageId = this.$route.query.id
      this.type = this.$route.query.type
      this.caseCenterList = this.$store.state.caseCenterData
      this.getNewsDetail(this.pageId)
    },
    methods: {
      getNewsDetail(id) {
        this.loading = true
        getAction(`/api/v1/dms/centralDynamics/queryDetail/${id}?dynamicType=${this.type}`, {}).then((res) => {
          if (res.success) {
            if (res && res.data) {
              this.newsInfo = res.data
            } else {
              this.newsInfo = {}
            }
          } else {
            this.newsInfo = {}
            message.error(res.message)
          }
        }).catch((err) => {
          this.newsInfo = {}
          message.error(err.message)
        }).finally(() => {
          this.loading = false
        })
      },
      // 上一篇
      handlePrevious(id) {
        if (!id) {
          this.$message({
            message: '已经是第一篇案例了~',
            type: 'warning'
          })
        } else {
          // 回到顶部，添加过渡动画
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          this.pageId = id
          this.getNewsDetail(this.pageId)
        }
        this.$nextTick(() => {
          this.$zh_tran(localStorage.getItem('lang'))
        })
      },
      // 下一篇
      handleNext(id) {
        if (!id) {
          this.$message({
            message: '已经是最后一篇案例了~',
            type: 'warning'
          })
        } else {
          // 回到顶部
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          this.pageId = id
          this.getNewsDetail(this.pageId)
        }
        this.$nextTick(() => {
          this.$zh_tran(localStorage.getItem('lang'))
        })
      }
    }
  }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .main-box {
    width: 1000px;
    margin: 85px auto 60px;

    .title {
      margin: 33px 0 20px 0;
      font-size: 24px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bottom-line {
        width: 30px;
        margin-top: 8px;
        border-bottom: 3px solid @primary-color;
      }
    }

    .height-box {
      min-height: 500px;
    }

    .news-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 20px;
    }

    .btn-box {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .other-title {
        display: block;
        font-size: 16px;
        color: @primary-color;
        font-weight: bold;
        padding-bottom: 1px;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;

        &:hover {
          opacity: 0.85;
          padding-bottom: 0;
          border-bottom: 1px solid @primary-color;
        }
      }
    }

    .news-title {
      font-size: 24px;
      color: @primary-color;
      font-weight: bold;
      margin: 20px 0;
    }

    .news-msg {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #A19F9F;
      margin-bottom: 20px;

      img {
        margin-right: 3px;
      }

      span {
        display: flex;
        align-items: center;
        margin-right: 30px;
      }
    }

    .news-content {
      width: 100%;
      min-height: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: rgba(51, 51, 51, 1);
    }
  }
}

@media (max-width: 768px) {
  .main-box {
    width: 100%;
    margin: 85px auto 60px;

    .title {
      margin: 33px 0 20px 0;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bottom-line {
        width: 30px;
        margin-top: 8px;
        border-bottom: 3px solid @primary-color;
      }
    }

    .height-box {
    }

    .news-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-top: 20px;
      padding: 0 10px;
    }

    .btn-box {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .other-title {
        margin-right: 10px;
        display: block;
        font-size: 14px;
        color: @primary-color;
        font-weight: bold;
        padding-bottom: 1px;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;

        &:hover {
          opacity: 0.85;
          padding-bottom: 0;
          border-bottom: 1px solid @primary-color;
        }
      }
    }

    .news-title {
      font-size: 20px;
      color: @primary-color;
      font-weight: bold;
      margin: 10px 0;
    }

    .news-msg {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      font-size: 14px;
      color: #A19F9F;
      margin-bottom: 20px;

      img {
        width: 14px;
        height: 14px;
        margin-right: 3px;
      }

      span {
        display: flex;
        align-items: center;
        margin-right: 15px;
        margin-bottom: 6px;
      }
    }

    .news-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: rgba(51, 51, 51, 1);
    }
  }
}

/deep/ .el-button--primary {
  background-color: @primary-color;
  border-color: @primary-color;
  color: #ffffff;

  &:hover {
    opacity: 0.85;
  }
}
</style>
