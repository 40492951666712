import account from './account'

const layout = ShowCourseWare()

function ShowCourseWare() {
  return [{
    path: '/',
    // redirect 是重新定向
    redirect: '/bayareacertification/index'
  }, {
    path: '/bayareacertification',
    component: () => import('@/components/project/layout/PLayoutBase'),
    redirect: '/bayareacertification/index',
    children: [{
      path: 'index',
      name: 'index',
      meta: { title: '首页' },
      component: () => import('@/views/index/Index')
    }, {
      path: 'caseCenter',
      name: 'caseCenter',
      meta: { title: '中心资讯' },
      component: () => import('@/views/gbacpc/caseCenter/caseCenter')
    }, {
      path: 'caseCenterDetail',
      name: 'CaseCenterDetail',
      meta: { title: '资讯详情' },
      component: () => import('@/views/gbacpc/caseCenter/caseCenterDetail')
    },
    {
      path: 'application',
      name: 'application',
      meta: { title: '认证申请' },
      component: () => import('@/views/gbacpc/application/application')
    },
    {
      path: 'query',
      name: 'query',
      meta: { title: '认证查询' },
      component: () => import('@/views/gbacpc/query/query')
    },
    {
      path: 'queryDetail',
      name: 'queryDetail',
      meta: { title: '认证查询详情' },
      component: () => import('@/views/gbacpc/query/queryDetail')
    },
    {
      path: 'technicalnorm',
      name: '认证标准',
      component: () => import('@/views/gbacpc/technicalnorm/technicalnorm')
    },
    {
      path: 'TechnicalnormDetail',
      name: '认证标准详情',
      component: () => import('@/views/gbacpc/technicalnorm/technicalnormDetail')
    },
    {
      path: 'classicCase',
      name: '典型案例',
      component: () => import('@/views/gbacpc/classiccase/ClassicCase')
    }, {
      path: 'classicCaseDetail',
      name: '典型案例详情',
      component: () => import('@/views/gbacpc/classiccase/ClassicCaseDetail')
    },
    {
      path: 'mediaCoverage',
      name: '媒体报道',
      component: () => import('@/views/gbacpc/mediacoverage/MediaCoverage')
    },
    {
      path: 'mediaCoverageDetail',
      name: '媒体报道详情',
      component: () => import('@/views/gbacpc/mediacoverage/MediaCoverageDetail')
    },
    {
      path: 'noticeDetail',
      name: '通知公告详情',
      component: () => import('@/views/index/modules/noticeDetail.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/privacy/Privacy.vue')
    },
    {
      path: 'aboutUs',
      name: '关于我们',
      component: () => import('@/views/gbacpc/aboutus/aboutUs.vue')
    },
    {
      path: 'appeals',
      name: '投诉申诉',
      component: () => import('@/views/gbacpc/appeals/appeals.vue')
    },
    {
      path: 'buyLink',
      name: '产品链接',
      component: () => import('@/views/gbacpc/query/buyLink.vue')
    },
    ...account]
  }]
}

export default layout
