import { request } from '@/utils/axios'
import { getAllUserByIds } from '@api/baseApi'
import { TOKEN_KEY } from '@api/tokenKey'
import { getAccessToken } from '@/utils/localStorage'
import { DOMIAN_URL } from '@/config'

/**
 * 根据条件查询认证产品
 * @param parameter
 * @returns {Promise<unknown>} res.result: [{CourseModel}]
 */
export function queryCertificateSearch(parameter) {
  return new Promise(resolve => {
    request({
      url: 'https://gbacpc.org.cn/jeecg-boot/api/v1/certificate/certificateSearch',
      method: 'get',
      params: parameter,
      verifyToken: true
    }).then(res => {
      if (res.success && res.list) {
        resolve(res)
      }
    })
  })
}


/**
 * 根据课程id获取认证产品信息
 * @param parameter
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export function getCertificateById(params) {
  return new Promise(resolve => {
    request({
      url: `https://gbacpc.org.cn/jeecg-boot/api/v1/certificate/detail/${params.id}`,
      method: 'get',
      verifyToken: true
    }).then(res => {
      resolve(res)
    })
  })
}

/**
 * 查询获证产品购买链接
 * @param parameter
 * @returns {Promise<unknown>} res.result: [{CourseModel}]
 */
export function queryCertificateLinkData(parameter) {
  return new Promise(resolve => {
    request({
      url: 'https://gbacpc.org.cn/jeecg-boot/api/v1/certificate/queryObtainProductPurchaseLink',
      method: 'get',
      params: parameter,
      verifyToken: true
    }).then(res => {
      resolve(res)
    })
  })
}
