<!--
 * @Description:
 * @Version: 1.0
 * @Autor: hekai
-->
<template>
  <div style="width: 100%;height: 100%;">
    <p-dv-border-box10 title="认证证书标色" class="header-cloud box top-area">
      <pie-chart :pieData="colorConfigData" style="width: 100%;height: 80%" @findIconColor="findIconColor"/>
    </p-dv-border-box10>
    <p-dv-border-box10 title="认证产品类别" class="header-rank box bottom-area">
      <radar-chart :pieData="productTypeData" width="100%" height="80%"/>
      <!--      <line-chart :lineData="lineData" style="width: 100%;height: 75%"></line-chart>-->
    </p-dv-border-box10>
  </div>
</template>

<script>
  import PDvBorderBox10 from '@comp/datav/border/PDvBorderBox10'
  import WordCloudChart from '@/chart/WordCloudChart'
  import PTabMenu from '@views/general/tab/PTabMenu'
  import LineChart from '@comp/project/echarts/LineChart'
  import BarChart from '@comp/project/echarts/BarChart'
  import PieChart from '@comp/project/echarts/PieChart'
  import RadarChart from '@comp/project/echarts/RadarChart'


  export default {
    name: 'HomeRight',
    components: { RadarChart, PieChart, BarChart, LineChart, PTabMenu, WordCloudChart, PDvBorderBox10 },
    props: {
      caseHotData: {
        type: Array,
        default: () => []
      },
      caseNewData: {
        type: Array,
        default: () => []
      },
      rankUnitData: {
        type: Array,
        default: () => []
      },
      rankPersonData: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        productTypeData: {
          data: [37, 65, 0, 9],
          name: ['工业消费品',
                 '农食产品',
                 '服务',
                 '绿色建材']
        },
        colorConfigData: [
          {
            name: '金标',
            value: 24
          },
          {
            name: '蓝标',
            value: 71
          },
          {
            name: '绿标',
            value: 16
          }
        ],
        lineData: {
          xData: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          yData: [5, 10, 12, 10, 12, 11, 10, 8, 2, 0, 0, 0]
        }
      }
    },
    watch: {},
    created() {
      this.initData()
    },
    methods: {
      initData() {
      },
      findIconColor(val) {
        this.$emit('findIconColor', val)
      },
      cloudRouterToSearch(data) {
        const routeUrl = this.$router.resolve({
          path: '/audit/searchPage',
          query: {
            search: data.name
          }
        })
        window.open(routeUrl.href, '_blank')
      },
      handleCase() {
        const routeUrl = this.$router.resolve({
          path: '/audit'
        })
        window.open(routeUrl.href, '_blank')
      }
    }
  }
</script>

<style lang="less" scoped>
@import '~@/assets/styles/home.less';

.box {
  width: 100%;
  margin-bottom: 10px;
}

.header-link {
  display: flex;
  justify-content: flex-end;
  color: #5087EC;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;

  span {
    cursor: pointer;
  }
}

.box-content {
  height: calc(100% - 40px);
}

.top-area {
  height: 20%;
  margin-bottom: 20px
}

.center-area {
  height: 51.5%;
  margin-bottom: 10px;

  .center-swipper {
    height: calc(100% - 20px);
  }
}

.bottom-area {
  height: 30%;
}

@height: 48%;

.header-cloud {
  background: url("~@/assets/bigscreen/bor1_4.png") no-repeat;
  background-size: 100% 100%;
  height: @height;

  .center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .charts-item {
      width: 53%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        width: 100%;
        margin: 0 auto;
        letter-spacing: 2px;
        line-height: 45px;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .charts-item-2 {
      .charts-item();
      width: 47%;
    }
  }
}

.header-case {
  background: url("~@/assets/bigscreen/bor1_5.png") no-repeat;
  background-size: 100% 100%;
  height: @height;
}

.header-rank {
  background: url("~@/assets/bigscreen/bor1_6.png") no-repeat;
  background-size: 100% 100%;
  height: @height;

  /deep/ .box-title {
    margin-top: 20px;
  }
}

@background: linear-gradient(180deg, rgba(18, 186, 255, 1) 0%, rgba(148, 236, 255, 1) 100%);
/deep/ .ranking-column {
  border-bottom: 1px solid rgb(125, 226, 255) !important;

  .inside-column {
    background: @background !important;
  }
}

/deep/ .rank {
  color: rgb(125, 226, 255) !important;
}

</style>
