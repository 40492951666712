<script>
  import EChart from '@comp/project/echarts/EChart.vue'
  import * as echarts from 'echarts'


  export default {
    name: 'PieChart',
    extends: EChart,
    props: {
      pieData: {
        type: Array,
        default: () => []
      },
      numberData: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        timer: null,
        option: {
          color: ['#FEA940', '#1E9BD1', '#31C5C0', '#0F347B', '#585247', '#7F6AAD', '#009D85', 'rgba(250,250,250,0.3)'],
          tooltip: {
            trigger: 'item'
          },
          grid: {
            bottom: 20,
            left: 100
          },
          legend: {
            textStyle: {
              color: '#f2f2f2',
              fontSize: 14

            },
            top: '87%',
            left: 'center'
          },
          series: [
            // 主要展示层的
            {
              radius: ['40%', '70%'],
              center: ['50%', '45%'],
              type: 'pie',
              tooltip: {
                show: false
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 16,
                  color: '#fff',
                  fontWeight: 'bold',
                  formatter: function (params) {
                    return `${params.name}\n${params.value}个`
                  }
                }
              },
              labelLine: {
                show: false
              },
              itemStyle: {
                normal: {
                  borderWidth: 5,
                  borderColor: 'rgba(0,0,0,1)'
                }

              },
              name: '',
              data: []
            },
            // 边框的设置
            {
              radius: ['35%', '38%'],
              center: ['50%', '45%'],
              clockWise: false, // 顺时加载
              hoverAnimation: false, // 鼠标移入变大
              type: 'pie',
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              labelLine: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              animation: false,
              tooltip: {
                show: false
              },
              data: [{
                value: 0,
                itemStyle: {
                  color: 'rgba(250,250,250,0.3)'
                }
              }]
            }, {
              name: '外边框',
              type: 'pie',
              clockWise: false, // 顺时加载
              tooltip: {
                show: false
              },
              hoverAnimation: false, // 鼠标移入变大
              center: ['50%', '45%'],
              radius: ['80%', '80%'],
              label: {
                normal: {
                  show: false
                }
              },
              data: [{
                value: 0,
                itemStyle: {
                  borderWidth: 4,
                  borderColor: '#0b5263'
                }
              }]
            }
          ]
        }
      }
    },
    mounted() {
      this.initData()
      this.$nextTick(() => {
        this.DrawPieArea(0)
      })
    },
    beforeDestroy() {
      clearInterval(this.timer)
    },
    watch: {
      pieData: {
        handler() {
          this.$nextTick(() => {
            this.setNameData(this.pieData)
          })
        },
        immediate: true,
        deep: true
      },
      numberData: {
        handler() {
          this.$nextTick(() => {
            this.setNumberData(this.numberData)
          })
        },
        immediate: true,
        deep: true
      }
    },
    computed: {},
    methods: {
      setNameData(data) {
        this.option.series[0].data = data
        this.updateOption()
      },
      setNumberData(data) {
        this.updateOption()
      },
      DrawPieArea(curIndex) {
        this.myChart = echarts.init(document.getElementById(this.id))
        this.myChart.setOption(this.option, true)

        if (curIndex == null) {
          this.myChart.dispatchAction({ type: 'highlight', seriesIndex: 0, dataIndex: 0 })
        } else {
          this.myChart.dispatchAction({ type: 'highlight', seriesIndex: 0, dataIndex: curIndex })
          this.$nextTick(() => {
            let that = this
            let timer = null

            const startHighlight = () => {
              this.timer = setInterval(() => {
                // 取消高亮
                that.myChart.dispatchAction({ type: 'downplay', seriesIndex: 0, dataIndex: curIndex })
                curIndex = (curIndex + 1) % 3
                // 设置高亮
                that.myChart.dispatchAction({ type: 'highlight', seriesIndex: 0, dataIndex: curIndex })
              }, 3000)
            }

            const stopHighlight = () => {
              clearInterval(this.timer)
            }

            startHighlight()

            that.myChart.on('mouseover', e => {
              stopHighlight()
              if (e.dataIndex !== curIndex) {
                that.myChart.dispatchAction({
                  type: 'downplay',
                  seriesIndex: 0,
                  dataIndex: curIndex
                })
              }
            })

            that.myChart.on('mouseout', e => {
              startHighlight()
              that.myChart.dispatchAction({
                type: 'highlight',
                seriesIndex: 0,
                dataIndex: curIndex
              })
            })

            that.myChart.on('click', function (e) {
              console.log(e)
              that.$emit('findIconColor', e.data.name)
            })
          })
        }
      }
    }
  }
</script>

<style lang='less' scoped>
</style>
