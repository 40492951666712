<!--
 * @Description:
 * @Version: 1.0
 * @Autor: hekai
-->
<template>
  <div class="box">
    <p-dv-border-box10 :showTitle="false" class="top-area">
      <div class="box-content center" style="height: 100%">
        <ul class="date-total" style="">
          <li>
            <span class="data-name">企业数量</span>
            <p class="data-count" style="color: #11DD9B">{{ caseInfo.companyNumber }}
              <span>
                家
             </span></p>
          </li>
          <li>
            <span class="data-name">证书数量</span>
            <p class="data-count" style="color: #01DFFF">{{ caseInfo.certificateNumber }}
              <span>
                张
             </span></p>
          </li>
          <li>
            <span class="data-name">品类数量</span>
            <p class="data-count" style="color:#F9A424">{{ caseInfo.productNumber }}
              <span>
                个
             </span>
            </p>
          </li>
          <li>
            <span class="data-name">品种数量</span>
            <p class="data-count" style="color: #01DFFF">{{ caseInfo.typeNumber }}
              <span>
                个
             </span>
            </p>
          </li>
          <!--          <li>-->
          <!--            <span class="data-name">指定认证机构</span>-->
          <!--            <p class="data-count" style="color: #01DFFF">{{ caseInfo.institutionNumber }}-->
          <!--              <span>-->
          <!--                家-->
          <!--             </span>-->
          <!--            </p>-->
          <!--          </li>-->
        </ul>
      </div>
    </p-dv-border-box10>
    <div class="center-map">
      <p-dv-border-box10 :showTitle="false" class="center-area">
        <map-chart class="map-box" @findArea="findArea"/>
      </p-dv-border-box10>
    </div>
  </div>
</template>

<script>

  import PDvBorderBox10 from '@comp/datav/border/PDvBorderBox10'
  import MapChart from '@comp/project/echarts/MapChart'

  export default {
    name: 'HomeMiddle',
    components: { MapChart, PDvBorderBox10 },
    data() {
      return {
        caseInfo: {
          companyNumber: '99',
          certificateNumber: '111',
          productNumber: '51',
          typeNumber: '91',
          institutionNumber: '10'
        },
        carouselList: [],
        playbillList: [],
        pptNum: 10
      }
    },
    created() {
      this.initData()
    },
    methods: {
      initData() {
      },
      findArea(val) {
        this.$emit('findArea', val)
      }
    }
  }
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;

  .top-area {
    height: 20%;
    margin-bottom: 10px
  }

  .center-map {
    width: 100%;
    height: 80%;

    .map-box {
      width: 100%;
      height: 100% !important;
    }
  }

  .center-area {
    height: 100%;
    margin-bottom: 10px;
  }

  .bottom-area {
    height: 30%;
  }

  .box-content {
    padding: 0 20px;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul.date-total {
    width: 100%;
    height: calc(100% - 10px);
    justify-content: space-evenly;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 18px 0;
    background: rgba(25, 56, 137, 0.23);
    border: 1px solid rgba(14, 50, 90, 1);
    border-left: 0;

    li {
      flex: 1;
      border-right: 1px solid #799DC4;
      text-align: center;

      &:last-child {
        border-right: none;
      }

      p {
        margin: 0;
      }

      .data-name {
        color: #ffffff;
        font-size: 24px;
        margin-bottom: 16px;
      }

      .data-count {
        font-size: 36px;
        font-weight: bold;
        margin-top: 10px;

        span {
          font-size: 24px;
        }
      }

    }
  }

  .box-carousel {
    width: 100%;
    overflow: hidden;
    margin: 10px 0;

    &-img {
      cursor: pointer;
      width: 100%;
      height: auto
    }


    &-title {
      float: left;
      position: relative;
      top: -36px;
      width: 100%;
      @height: 34px;
      height: @height;
      line-height: @height;
      padding: 0 40px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #fff;
      font-size: 18px;
      background: rgba(17, 17, 17, 0.6);
    }

  }

  .box-playbill {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
}

/deep/ .el-carousel {
  height: 100%;
  width: 100%;
}

/deep/ .el-carousel__container {
  height: 100% !important;
  width: 100%;
}

</style>
