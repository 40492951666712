<template>
  <div class="breadcrumb">
    <div class="breadcrumb-home">
      <img src="@/assets/caseCenter/home_icon.png" alt="" width="18" height="18">
      <div class="text">当前位置：</div>
    </div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        首页
      </el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item, index) in menuList" :key="index" :to="item.path">{{
          item.name
        }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'

  export default {
    name: 'Breadcurmb',
    props: {
      menuList: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style scoped lang="less">
@media (min-width: 768px) {
  .breadcrumb {
    padding-top: 30px;
    margin-left: 10px;
    display: flex;
    align-items: center;

    .breadcrumb-home {
      display: flex;
      align-items: center;

      .text {
        margin-left: 5px;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 768px) {
  .breadcrumb {
    padding-top: 15px;
    margin-left: 10px;
    display: flex;
    align-items: center;

    .breadcrumb-home {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

      .text {
        margin-left: 5px;
        font-size: 16px;
      }
    }
  }
}

/deep/ .el-breadcrumb {
  font-size: 16px;
}

/deep/ .el-breadcrumb__inner.is-link {
  color: @primary-color;
}

/deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: @primary-color;
}
</style>
