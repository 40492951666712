<template>
  <div class="bg">
    <div class="banner">
      <breadcurmb :menuList="menuList"></breadcurmb>
      <div class="title">
        <div>获证产品购买链接</div>
        <div class="bottom-line"></div>
      </div>
      <!--      <div class="filter-box">-->
      <!--        <el-form :inline="true" :model="form" class="demo-form-inline">-->
      <!--          <el-form-item label="证书编号">-->
      <!--            <el-input v-model="form.certificateNumber" placeholder="证书编号" clearable></el-input>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item label="公司名称">-->
      <!--            <el-input v-model="form.companyName" placeholder="公司名称" clearable></el-input>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item label="产品名称">-->
      <!--            <el-input v-model="form.productName" placeholder="产品名称" clearable></el-input>-->
      <!--          </el-form-item>-->
      <!--          <el-form-item>-->
      <!--            <el-button type="primary" icon="el-icon-search" @click="searchData">查询</el-button>-->
      <!--            <el-button icon="el-icon-refresh-right" @click="resetData">重置</el-button>-->
      <!--          </el-form-item>-->
      <!--        </el-form>-->
      <!--      </div>-->
      <div class="search-box">
        <el-input
          placeholder="请输入关键字查询（证书编号/公司名称/产品名称）"
          v-model="searchValue"
          clearable>
          <el-button slot="append" icon="el-icon-search" @click="searchData">搜索</el-button>
        </el-input>
      </div>
      <div v-loading="loading">
        <el-empty description="暂无数据" v-if="dataList.length === 0 && !loading"></el-empty>
        <div v-else class="link-box">
          <div class="link-item" v-for="(item, index) in dataList" :key="index">
            <div class="top-text">
              <div class="text-title">
                <span>证书编号：</span>
                {{ item.certificateNumber }}
              </div>
              <div class="text-title">
                <span>公司名称：</span>
                {{ item.companyName }}
              </div>
              <div class="text-title multi-line-ellipsis">
                <span>产品名称：</span>
                {{ item.productName }}
              </div>
            </div>
            <div class="bottom-img" v-if="item.supplyPlatformPurchaseLinkList.length > 0">
              <div
                class="item-img"
                v-for="(j,k) in item.supplyPlatformPurchaseLinkList"
                :key="k"
                @click="toDetail(j)">
                <img :src="j.logo" alt="" width="100%" height="100%">
                <span>{{ j.supplyPlatformName }}</span>
              </div>
            </div>
            <div class="empty-box" v-else>
              <img src="@assets/index/query/none.png" alt="">
              <span>暂无购买链接</span>
            </div>
          </div>
        </div>
        <div class="pagination" v-if="pagination.totalSize > pagination.pageSize">
          <p-pagination-base
            :currentPage.sync="pagination.currentPage"
            :pageSize.sync="pagination.pageSize"
            :page-sizes="[20, 30, 40]"
            :total="pagination.totalSize"
            v-show="!this.isEmpty"
            @size-change="handleSizeChange"
            @currentPageChange="changeCurrent"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Breadcurmb from '@/components/breadcrumb/Breadcurmb.vue'
  import PPaginationBase from '@comp/project/pagination/PPaginationBase'
  import { message } from 'ant-design-vue'
  import { queryCertificateLinkData } from './api/api'

  export default {
    name: 'BuyLink',
    components: { Breadcurmb, PPaginationBase },
    data() {
      return {
        loading: false,
        searchValue: '',
        typeValue: '',
        form: {},
        menuList: [
          {
            name: '获证产品购买链接',
            path: ''
          }
        ],
        dataList: [],
        activeCategory: '',
        allKey: {
          category: {
            name: '全部',
            id: null
          }
        },
        category: [
          {
            id: 'A',
            name: '工业消费品'
          },
          {
            id: 'B',
            name: '农食产品'
          },
          {
            id: 'C',
            name: '服务'
          },
          {
            id: 'D',
            name: '绿色建材'
          }
        ],
        pagination: {
          currentPage: 1,
          pageSize: 10,
          totalSize: 0
        }
      }
    },
    computed: {
      isEmpty() {
        return this.dataList.length === 0
      }
    },
    created() {
      this.init()
      this.activeCategory = this.allKey.category.id
    },
    methods: {
      searchData() {
        this.loadData(1)
      },
      resetData() {
        this.form = {
          certificateNumber: '',
          companyName: '',
          productName: ''
        }
        this.loadData(1)
      },
      changeCategory(item) {
        this.activeCategory = item.id
        this.loadData(1)
      },
      init() {
        this.loading = true
        let promiseList = [this.loadData(1, false)]
        Promise.all(promiseList).finally(res => {
          this.loading = false
        })
      },
      changeCurrent(page) {
        this.pagination.currentPage = page
        this.loadData(page)
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.loadData(1)
      },
      loadData(arg, isLoading = true) {
        return new Promise((resolve, reject) => {
          if (isLoading) {
            this.loading = true
          }
          if (arg === 1) {
            this.pagination.currentPage = 1
          }
          let params = {
            certificationCategory: this.activeCategory || null,
            keyword: this.searchValue,
            queryPage: this.pagination.currentPage,
            querySize: this.pagination.pageSize
          }
          queryCertificateLinkData(params).then((res) => {
            if (res.success) {
              if (res.result.records.length > 0) {
                let data = res.result.records
                this.dataList = data.map(item => {
                  return {
                    id: item.id,
                    certificateNumber: item.certificateNumber,
                    companyName: item.companyName,
                    productName: item.productName,
                    supplyPlatformPurchaseLinkList: item.supplyPlatformPurchaseLinkList
                  }
                })
                this.pagination.currentPage = res.result.current
                this.pagination.pageSize = res.result.size
                this.pagination.totalSize = res.result.total
              } else {
                this.dataList = []
              }
              resolve(res)
            } else {
              this.dataList = []
              message.error(res.message)
              reject(res)
            }
          }).catch((err) => {
            this.dataList = []
            message.error(err.message)
            reject(err)
          }).finally(() => {
            this.loading = false
          })
          this.$nextTick(() => {
            this.$zh_tran(localStorage.getItem('lang'))
          })
        })
      },
      toDetail(record) {
        window.open(record.purchaseLink, '_blank')
      }
    }
  }
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
  margin-top: 85px;
}

@media (min-width: 768px) {
  .banner {
    width: 1000px;
    height: 100%;
    margin: 0 auto;

    .search-box {
      width: 1000px;
      margin: 0 auto 20px;
      display: flex;
      align-items: center;
    }

    .title {
      margin: 33px 0;
      font-size: 24px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bottom-line {
        width: 30px;
        margin-top: 8px;
        border-bottom: 3px solid @primary-color;
      }
    }

    .link-box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .link-item {
      width: calc(50% - 15px);
      height: 260px;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      padding: 20px;
      border-radius: 20px;
      box-shadow: 0 4px 16px #08174f14;
      background: rgba(250, 250, 250, 1) url("~@/assets/index/query/card-back-img.png");
      background-size: cover;


      .top-text {
        width: 100%;
        height: 130px;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        border-bottom: 1px solid #cccccc30;

        .text-title {
          color: #000000;
          font-size: 16px;
          margin: 5px 0;

          span {
            font-weight: bold;
          }
        }

        .multi-line-ellipsis {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

        }
      }

      .bottom-img {
        width: 100%;
        height: calc(100% - 130px);
        display: flex;
        flex-wrap: wrap;

        .item-img {
          width: 120px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 4px 16px #08174f14;
          border-radius: 10px;
          cursor: pointer;
          margin-right: 10px;
          margin-bottom: 10px;
          transition: all .4s ease;

          &:hover {
            transform: translateY(-4px);
          }

          img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }
        }
      }

      .empty-box {
        width: 100%;
        height: calc(100% - 130px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
          color: #cccccc;
        }
      }
    }

    .pagination {
      text-align: center;
      margin-bottom: 30px;
    }
  }

}

@media (max-width: 768px) {
  .banner {
    width: 100%;
    height: 100%;
    margin: 0 auto;

    .search-box {
      width: 90%;
      margin: 0 auto 20px;
      display: flex;
      align-items: center;
    }

    .title {
      margin: 15px 0;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bottom-line {
        width: 30px;
        margin-top: 8px;
        border-bottom: 3px solid @primary-color;
      }
    }

    .link-box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 10px;
    }

    .link-item {
      width: calc(50% - 5px);
      height: 300px;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      padding: 20px;
      border-radius: 20px;
      box-shadow: 0 4px 16px #08174f14;
      background: rgba(250, 250, 250, 1) url("~@/assets/index/query/card-back-img.png");
      background-size: cover;


      .top-text {
        width: 100%;
        height: 160px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        border-bottom: 1px solid #cccccc30;

        .text-title {
          color: #000000;
          font-size: 14px;
          margin: 5px 0;

          span {
            font-weight: bold;
          }
        }

        .multi-line-ellipsis {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

        }
      }

      .bottom-img {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .item-img {
          width: 40%;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 4px 16px #08174f14;
          border-radius: 10px;
          cursor: pointer;
          font-size: 12px;
          margin-right: 5px;
          transition: all .4s ease;


          &:hover {
            transform: translateY(-4px);
          }

          img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
          }

          span {
            overflow: hidden; /* 隐藏溢出的内容 */
            white-space: nowrap; /* 不换行 */
            text-overflow: ellipsis;
          }
        }
      }
    }

    .pagination {
      text-align: center;
      margin-bottom: 30px;
    }
  }

}

.single-line-ellipsis {
  overflow: hidden; /* 隐藏溢出的内容 */
  white-space: nowrap; /* 不换行 */
  text-overflow: ellipsis; /* 溢出的部分用省略号表示 */
}

.multi-line-ellipsis {
  display: -webkit-box; /* 使用弹性盒子模型 */
  -webkit-box-orient: vertical; /* 子元素垂直排列 */
  -webkit-line-clamp: 4; /* 显示的最大行数 */
  overflow: hidden; /* 隐藏溢出的内容 */
}

.filter-box {
  display: flex;
  justify-content: center;
  width: 1000px;
  margin: 20px auto 0;
  height: 80px;

  .demo-form-inline {
  }

  /deep/ .el-input--suffix .el-input__inner {
    width: 180px;
  }

  .btn-bgc {
    display: inline-block;
    width: 89px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
    background-color: rgba(45, 108, 163, 0.51);
    color: rgba(250, 250, 250, 1);
    font-size: 16px;
    margin-right: 20px;
  }
}

.pos {
  width: 100%;
  padding-top: 8px;
  overflow: hidden;
  display: flex;
  font-size: 16px;
  transition: all .3s;
}


.type-title {
  margin: 0 14px;
  line-height: 28px;
  white-space: nowrap;
  font-weight: bold;
}


.item-list {
  display: flex;
  flex-wrap: wrap;


  .item {
    height: 32px;
    color: #545c63;
    padding: 4px 12px;
    margin: 0 12px 8px 0;
    border-radius: 6px;
  }

  .active-item {
    color: #FFFFFF;
    background-color: @primary-color;
  }

  .item:hover {
    cursor: pointer;
  }
}

/deep/ .el-button--small {
  font-size: 16px;
}

/deep/ .el-button {
  border-color: @primary-color;
  background-color: @primary-color;
  color: #ffffff;
}

/deep/ .el-input-group__append button.el-button {
  border-color: @primary-color;
  background-color: @primary-color;
  color: #ffffff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top: 1px solid @primary-color;
  border-bottom: 1px solid @primary-color;
}

/deep/ .el-button--text {
  color: @primary-color;
}

/deep/ .el-input {
  font-size: 16px;
}

/deep/ .el-input__inner {
  height: auto;
}

/deep/ .el-input__icon {
  font-size: 16px;
}

/deep/ .el-select .el-input .el-select__caret {
  font-size: 16px;
}
</style>
