<template>
  <div class="bg">
    <div class="main-box">
      <breadcurmb :menuList="menuList"></breadcurmb>
      <div class="file-box">
        <div class="file-title">
          {{ standardsInfo.name }}
        </div>
        <div class="bottom-line"></div>
        <div class="file-msg">
          <div class="img-text">
            <img src="@assets/caseCenter/p-time.png" alt="">
            发布时间：{{ standardsInfo.releaseTime || '-' }}
          </div>
          <div class="img-text">
            <img src="@assets/caseCenter/views.png" alt="">
            浏览次数：{{ standardsInfo.viewCount || 0 }}
          </div>
        </div>
        <!--        pdf预览-->
        <div class="pdf-box" v-loading="loading">
          <div class="empty" v-if="numPages.length === 0">
            <el-empty description="暂无内容"></el-empty>
          </div>
          <template v-else>
            <pdf
              v-for="item in numPages"
              :key="item"
              :page="item"
              :src="standardsInfo.standardFilePath"
              ref="pdf"></pdf>
          </template>
          <!--          <iframe-->
          <!--            id="iframe"-->
          <!--            :src="standardsInfo.pdfUrl + '#toolbar=0'"-->
          <!--            width="100%"-->
          <!--            @contextmenu.prevent="disableContextMenu"-->
          <!--            height="100%"></iframe>-->
          <span>文件仅支持查看部分内容，如需查看完整内容请前往
            <a href="http://system.gbacpc.wellaudit.cn/" target="_blank">认证申请</a>
            注册</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pdf from 'vue-pdf'
  import Breadcurmb from '@/components/breadcrumb/Breadcurmb'

  import {
    standardsData
  } from '@/api/mock/homeIndex'
  import { getAction } from '@api/manage'
  import { accreditationStandardName, standardStatusName } from '@/enum/accreditationStandard'
  import { message } from 'ant-design-vue'
  import fontkit from '@pdf-lib/fontkit'
  import {
    degrees,
    PDFDocument,
    rgb
  } from 'pdf-lib'

  export default {
    name: 'TechnicalnormDetail',
    components: { Breadcurmb, pdf },
    data() {
      return {
        loading: false,
        standardsInfo: {},
        standardsData: standardsData,
        numPages: [],
        menuList: [
          {
            name: '认证标准',
            path: '/bayareacertification/technicalnorm'
          },
          {
            name: '认证标准详情',
            path: ''
          }
        ]
      }
    },
    created() {
      this.getTableData()
      // 如禁止f12 代码没生效，加下面这段
      document.onkeydown = function (e) {
        e.returnValue = false
        if (e && e.keyCode === 123) {
          // e.keyCode = 0   //去掉也可以，如果要写，则需要setter + getter配合使用，不然则会报错
          return false
        }
        if (e && e.ctrlKey && e.shiftKey) {
          return false
        }
      }
      // 禁止鼠标右键
      document.oncontextmenu = function () {
        return false
      }
    },
    beforeDestroy() {
      // 如禁止f12 代码没生效，加下面这段
      document.onkeydown = function (e) {
        if (e && e.keyCode === 123) {
          e.returnValue = true
          // e.keyCode = 0   //去掉也可以，如果要写，则需要setter + getter配合使用，不然则会报错
          return true
        }
      }
      // 禁止鼠标右键
      document.oncontextmenu = function () {
        return true
      }
    },
    methods: {
      getNumPages() {
        let loadingTask = pdf.createLoadingTask(this.standardsInfo.standardFilePath)
        loadingTask.promise.then(pdf => {
          this.numPages = pdf.numPages > 5 ? 4 : pdf.numPages
          this.loading = false
        }).catch(err => {
          this.loading = false
          console.error('pdf 加载失败', err)
        })
      },
      getTableData() {
        this.loading = true
        // this.standardsInfo = this.standardsData[this.$route.query.type].filter(item => item.id === this.$route.query.id)[0]
        getAction(`/api/v1/dms/standard/queryDetail/${this.$route.query.id}`, {}).then((res) => {
          if (res.success) {
            if (res && res.data) {
              this.standardsInfo = res.data
              this.addWarker(this.standardsInfo.standardFilePath, '广东粤港澳大湾区认证促进中心')
            } else {
              this.loading = false
              this.standardsInfo = {}
            }
          } else {
            this.standardsInfo = {}
            this.loading = false
            message.error(res.message)
          }
        }).catch((err) => {
          this.standardsInfo = {}
          this.loading = false
          message.error(err.message)
        }).finally(() => {
          this.getNumPages()
        })
      },
      disableContextMenu() {
        let dom = document.getElementsByClassName('main-box')
        // 禁用右键
        dom.onload = function () {
          dom.oncontextmenu = function () {
            return false
          }
        }
        // 禁用选择
        dom.onselectstart = function () {
          return false
        }
        // 禁止f12
        dom.onkeydown = function () {
          return false
        }
      },
      // 添加水印
      addWarker(url, warkerName) {
        let that = this
        changeBlob().then((res) => {
          res.arrayBuffer().then((rel) => {
            // 获取arrauBuffer文件
            viewmodifyPdf(rel)
          })
        })

        // 地址转文件
        function changeBlob() {
          return new Promise((resolve) => {
            const xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = () => {
              if (xhr.status === 200) {
                resolve(xhr.response)
              }
            }
            xhr.send()
          })
        }

        function loadImg(img) {
          return new Promise((resolve) => {
            let image = new Image()
            image.setAttribute('crossOrigin', 'anonymous')
            image.src = img
            image.onload = () => {
              let canvas = document.createElement('canvas')
              canvas.width = image.width
              canvas.height = image.height
              let ctx = canvas.getContext('2d')
              ctx.drawImage(image, 0, 0, image.width, image.height)
              canvas.toBlob((blob) => {
                resolve(blob)
              })
            }
          })
        }

        // 预览水印文件
        async function viewmodifyPdf(file) {
          // 将arrayBuff文件加载pdf文件
          const pdfDoc = await PDFDocument.load(file)
          // 引入自定义字体
          let url = require('@/assets/fonts/PingFangSCRegular.ttf')
          let img = '@/assets/index/logo.png'
          const fontBytes = await fetch(url).then((res) => res.arrayBuffer())
          // const imgBytes = await loadImg(img).then((res) => res.arrayBuffer())
          // 自定义字体挂载
          pdfDoc.registerFontkit(fontkit)
          const customFont = await pdfDoc.embedFont(fontBytes)
          // const _img = await pdfDoc.embedJpg(imgBytes)
          // 获取文档所有页
          const pages = pdfDoc.getPages()
          // 文字渲染配置
          for (let i = 0; i < pages.length; i++) {
            const noPage = pages[i]
            const {
              width,
              height
            } = noPage.getSize()
            for (let i = 0; i < 10; i++) {
              for (let j = 0; j < 3; j++) {
                noPage.drawText(warkerName, {
                  x: 230 * j,
                  y: (height / 4) * i,
                  size: 16,
                  font: customFont, // 这里使用的是自定义字体
                  color: rgb(0.46, 0.53, 0.6),
                  rotate: degrees(-45),
                  opacity: 0.3
                })
              // noPage.drawImage(_img, {
              //   x: 230 * j,
              //   y: (height / 4) * i,
              //   width: 15,
              //   height: 15,
              //   opacity: 0.7
              // })
              }
            }
          }
          // 获取水印文件
          const pdfBytes = await pdfDoc.save()
          let blobData = new Blob([pdfBytes], {
            type: 'application/pdf;Base64'
          })
          that.url = window.URL.createObjectURL(blobData) + '#toolbar=0'
          that.standardsInfo.standardFilePath = that.url
        }
      }
    }
  }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .bg {
    width: 100%;
    margin-top: 85px;
    background: url("~@/assets/index/technicalnorm/technicalnorm-bg.png") no-repeat;
  }

  .main-box {
    width: 1000px;
    margin: 0 auto 60px;
  }

  .title {
    padding: 33px 0;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom-line {
      width: 30px;
      margin-top: 8px;
      border-bottom: 3px solid @primary-color;
    }
  }

  .file-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .file-title {
      font-size: 24px;
      color: @primary-color;
      font-weight: bold;
      margin: 50px 0 20px;
    }

    .bottom-line {
      width: 100%;
      border-bottom: 1px solid rgba(187, 187, 187, 1);
    }

    .file-msg {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 0;
      color: #A19F9F;

      .img-text {
        display: flex;
        align-items: center;

        img {
          margin-right: 3px;
        }
      }
    }

    .pdf-box {
      width: 100%;
      height: 600px;
      overflow-y: scroll;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 10px;
    }

    .empty {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 768px) {
  .bg {
    width: 100%;
    margin-top: 85px;
    background: url("~@/assets/index/technicalnorm/technicalnorm-bg.png") no-repeat;
  }

  .main-box {
    width: 100%;
    margin: 0 auto 60px;
    padding: 0 10px;
  }

  .title {
    padding: 15px 0;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom-line {
      width: 30px;
      margin-top: 8px;
      border-bottom: 3px solid @primary-color;
    }
  }

  .file-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .file-title {
      font-size: 20px;
      color: @primary-color;
      font-weight: bold;
      margin: 20px 0 20px;
    }

    .bottom-line {
      width: 100%;
      border-bottom: 1px solid rgba(187, 187, 187, 1);
    }

    .file-msg {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 0;
      color: #A19F9F;

      .img-text {
        display: flex;
        align-items: center;

        img {
          width: 14px;
          height: 14px;
          margin-right: 3px;
        }
      }
    }

    .pdf-box {
      width: 100%;
      height: 600px;
      overflow-y: scroll;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 10px;
    }

    .empty {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
