<template>
  <div class="bg">
    <div class="banner">
      <breadcurmb :menuList="menuList"></breadcurmb>
      <div class="title">
        <div>典型案例</div>
        <div class="bottom-line"></div>
      </div>
      <div v-loading="loading">
        <el-empty description="暂无数据" v-if="dataList.length === 0 && !loading"></el-empty>
        <div v-else>
          <div class="box-item">
            <div class="content" v-for="(item, index) in dataList" :key="index" @click="toDetail(item)">
              <div class="right-text">
                <div class="left">
                  <div class="text-title single-line-ellipsis">{{ item.title }}</div>
                  <div class="text-content multi-line-ellipsis">发布时间：{{ item.date }}</div>
                </div>
              </div>
              <div class="left-img">
                <img :src="item.coverPictureUrl" alt="" width="100%" height="100%">
                <img class="play-icon" src="@/assets/caseDetails/playDetai.png" alt="" width="100%" height="100%">
              </div>
            </div>
          </div>
          <div class="pagination" v-if="pagination.totalSize > pagination.pageSize">
            <p-pagination-base
              :currentPage.sync="pagination.currentPage"
              :pageSize.sync="pagination.pageSize"
              :page-sizes="[20, 30, 40]"
              :total="pagination.totalSize"
              v-show="!this.isEmpty"
              @size-change="handleSizeChange"
              @currentPageChange="changeCurrent"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Breadcurmb from '@/components/breadcrumb/Breadcurmb.vue'
  import PPaginationBase from '@comp/project/pagination/PPaginationBase'
  import { newsList } from '@/api/mock/homeIndex'
  import { getAction } from '@api/manage'
  import { message } from 'ant-design-vue'


  export default {
    name: 'AuditStory',
    components: { Breadcurmb, PPaginationBase },
    data() {
      return {
        loading: false,
        menuList: [
          {
            name: '典型案例',
            path: ''
          }
        ],
        dataList: [],
        pagination: {
          currentPage: 1,
          pageSize: 6,
          totalSize: 0
        }
      }
    },
    computed: {
      isEmpty() {
        return this.dataList.length === 0
      }
    },
    created() {
      this.init()
    },
    methods: {
      init() {
        this.loading = true
        let promiseList = [this.loadData(1, false)]
        Promise.all(promiseList).finally(res => {
          this.loading = false
        })
      },
      changeCurrent(page) {
        this.pagination.currentPage = page
        this.loadData(page)
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.loadData(1)
      },
      loadData(arg, isLoading = true) {
        return new Promise((resolve, reject) => {
          if (isLoading) {
            this.loading = true
          }
          if (arg === 1) {
            this.pagination.currentPage = 1
          }
          let params = {
            dynamicType: '2',
            queryPage: this.pagination.currentPage,
            querySize: this.pagination.pageSize
          }
          getAction('/api/v1/dms/centralDynamics/detail', params).then((res) => {
            if (res.success) {
              if (res && res.list && res.list.length > 0) {
                this.dataList = res.list.map(item => {
                  return {
                    id: item.id,
                    coverPictureUrl: item.coverImage,
                    title: item.title,
                    date: item.releaseTime.slice(0, 10),
                    content: item.content
                  }
                })
                this.pagination.currentPage = res.page
                this.pagination.pageSize = res.size
                this.pagination.totalSize = res.totalSize
              } else {
                this.dataList = []
              }
              resolve(res)
            } else {
              this.dataList = []
              message.error(res.message)
              reject(res)
            }
          }).catch((err) => {
            this.dataList = []
            message.error(err.message)
            reject(err)
          }).finally(() => {
            this.loading = false
          })
          this.$nextTick(() => {
            this.$zh_tran(localStorage.getItem('lang'))
          })
        })
      },
      toDetail(record) {
        this.$router.push({
          path: '/bayareacertification/classicCaseDetail',
          query: {
            id: record.id,
            type: '2'
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
  margin-top: 85px;
}

@media (min-width: 768px) {
  .banner {
    width: 1000px;
    height: 100%;
    margin: 0 auto;

    .title {
      margin: 33px 0;
      font-size: 24px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bottom-line {
        width: 30px;
        margin-top: 8px;
        border-bottom: 3px solid @primary-color;
      }
    }

    .box-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .content {
      width: calc(50% - 15px);
      display: flex;
      background-color: rgba(250, 250, 250, 1);
      margin-bottom: 30px;
      transition: all .4s ease;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 0 4px 16px #08174f14;

      &:hover {
        transform: translateY(-4px);
      }

      .left-img {
        position: relative;
        width: 290px;
        height: 185px;
        text-align: center;
        padding: 10px 10px;

        .play-icon {
          width: 60px;
          height: 60px;
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          transition: all .4s ease;
        }

        &:hover {
          // 悬浮显示播放图标
          .play-icon {
            display: block;
          }
        }
      }

      .right-text {
        width: 70%;
        display: flex;
        padding: 10px;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 10px 20px 10px 0;
          transition: 0.3s all;

          .text-title {
            font-size: 20px;
            font-weight: bold;
            text-align: justify;
          }

          .text-content {
            color: #999999;
            font-size: 16px;
          }

          &:hover {
            color: @primary-color;
          }
        }

        .right {
          width: 10%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: @primary-color;

          .day {
            font-size: 24px;
            font-weight: bold;
          }

          .date {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }

    .pagination {
      text-align: center;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 768px) {
  .banner {
    width: 100%;
    height: 100%;
    margin: 0 auto;

    .title {
      margin: 15px 0;
      font-size: 20px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bottom-line {
        width: 30px;
        margin-top: 8px;
        border-bottom: 3px solid @primary-color;
      }
    }

    .box-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 10px;
    }

    .content {
      width: calc(50% - 15px);
      display: flex;
      flex-direction: column-reverse;
      background-color: rgba(250, 250, 250, 1);
      margin-bottom: 30px;
      transition: all .4s ease;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 0 4px 16px #08174f14;

      &:hover {
        transform: translateY(-4px);
      }

      .left-img {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 10px 10px;

        .play-icon {
          width: 30px;
          height: 30px;
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          transition: all .4s ease;
        }

        &:hover {
          // 悬浮显示播放图标
          .play-icon {
            display: block;
          }
        }
      }

      .right-text {
        width: 100%;
        display: flex;
        padding: 10px;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 10px 20px 10px 0;
          transition: 0.3s all;

          .text-title {
            font-size: 18px;
            font-weight: bold;
            text-align: justify;
            margin-bottom: 3px;
          }

          .text-content {
            color: #999999;
            font-size: 14px;
          }

          &:hover {
            color: @primary-color;
          }
        }

        .right {
          width: 10%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: @primary-color;

          .day {
            font-size: 24px;
            font-weight: bold;
          }

          .date {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }

    .pagination {
      text-align: center;
      margin-bottom: 30px;
    }
  }
}

.single-line-ellipsis {
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 溢出的部分用省略号表示 */
  display: -webkit-box; /* 使用弹性盒子模型 */
  -webkit-box-orient: vertical; /* 子元素垂直排列 */
  -webkit-line-clamp: 3; /* 显示的最大行数 */
}

.multi-line-ellipsis {
  display: -webkit-box; /* 使用弹性盒子模型 */
  -webkit-box-orient: vertical; /* 子元素垂直排列 */
  -webkit-line-clamp: 4; /* 显示的最大行数 */
  overflow: hidden; /* 隐藏溢出的内容 */
}
</style>
